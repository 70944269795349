<template>
  <div>
    <header-slot>
      <template #actions>
        <b-row class="justify-content-end mr-1">
          <b-button variant="outline-danger" :to="`/${routeModule}`">
            Cancel
          </b-button>
          <b-button variant="primary" class="ml-1" @click="updateUser">
            {{ $route.meta.tittleBtn }}
          </b-button>
        </b-row>
      </template>
    </header-slot>
    <template>
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <user-view-info-card
            ref="cardComponent"
            :user-data="userData"
            :form="form"
            :modules="modules"
            :programs-options="programsOptions"
          />
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
import EditService from "../services/edit-user.service";
import UserViewInfoCard from "./UserViewInfoCard.vue";

export default {
  components: {
    UserViewInfoCard,
  },
  data() {
    return {
      userData: {
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        country: "PE",
        password: "",
        phone: "",
        cellphone: "",
        date_of_birth: moment().format("YYYY-MM-DD"),
        restriction_by_ip: false,
        ticket_reviewer: false,
        language: 0,
        skill: { id: 0, name: "Select a skill" },
        status: false,
        module: null,
        role: null,
        typesenior: "",
        rolenew: null,
        file: "",
        image: "",
        admin: null,
        creditExpertAdmin: 1,
        others: "0",
        super: "",
        superid: "",
        extension: "",
        social_network: null,
        newarr: [],
        program: [],
        mainmodule: null,
      },
      form: {
        file: "",
        image: "",
        remove: false,
      },
      programsOptions: [],
      modules: [],
      deletedItem: {},
      wasDeleted: false,
      idsUserModule: [],
      uniqueModuleCedChild: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    routeModule() {
      return this.$route.meta.route;
    },
    containsChiefCreditExpertsDigital() {
      return (
        this.userData.newarr.filter(
          (item) => item.id_module === 21 && item.id_role === 17
        ).length > 0
      );
    },
    containsChiefEspecialists() {
      return (
        this.userData.newarr.filter(
          (item) => item.id_module === 11 && item.id_role === 17
        ).length > 0
      );
    },
    especialistSubModules() {
      return (
        this.userData.newarr.filter((item) => item.parent_id === 11).length > 0
      );
    },

    containsAChildOfCreditExpertsDigital() {
      return (
        this.userData.newarr.filter((item) => item.parent_id === 21).length > 0
      );
    },
    containsCreditExpertsRegular() {
      return (
        this.userData.newarr.filter((item) => item.id_module === 6).length > 0
      );
    },
    containsCedModule() {
      return this.userData.newarr.filter((item) => item.id === 21).length > 0;
    },
  },
  async created() {
    this.addPreloader();
    if (this.$route.params.id) {
      await this.getInfoUser();
    }
    this.userData.superid = this.currentUser.user_id;
    this.userData.super = this.currentUser.role_id;
    await Promise.all([this.getAllPrograms(), this.getAllModules()]);
    this.removePreloader();
  },
  methods: {
    async getAllPrograms() {
      try {
        this.programsOptions = await EditService.getAllPrograms();
      } catch (e) {
        this.removePreloader();
        throw e;
      }
    },
    async getAllModules() {
      try {
        const { data } = await helpdeskService.getModulesV2();
        this.modules = data;
      } catch (e) {
        this.removePreloader();
        throw e;
      }
    },
    async getInfoUser() {
      try {
        const { data } = await EditService.getUserEdit({
          id: this.$route.params.id,
        });
        this.idsUserModule = [];
        this.userData = { ...data[0] };
        this.$set(this.userData, "admin", this.userData.admin_type);
        this.$set(this.userData, "checkEmail", this.userData.email);
        this.$set(this.userData, "skill", {
          id: this.userData.lang,
          name: "Select a skill",
        });
        // this.userData.skill.id = this.userData.lang
        this.form.image = this.userData.image;
        const arrPermission = this.userData.sale_permission
          ? this.userData.sale_permission
          : [];
        this.$set(this.userData, "program", arrPermission);
        let module = this.userData.newarr.find(
          ({ main_module }) => main_module === 1
        );
        module = module ? module.id_module : null;
        this.$set(this.userData, "mainmodule", module);

        // get ids

        // get module_id
      } catch (e) {
        this.removePreloader();
        throw e;
      }
    },
    async updateUser() {
      try {
        const refForm = this.$refs.cardComponent;
        const answer = await refForm.$refs.form.validate();
        let isAnElement = true;
        let isMain = false;
        this.userData.language = this.userData.skill.id;
        if (this.userData.admin === 2) {
          // if (!this.userData.newarr.find((item) => item.parent_id !== 21)) {

          // return ids of subModules if parent_id is 21
          const subModules = this.modules
            .filter((item) => item.parent_id === 21)
            .map((item) => item.id);
          if (subModules.includes(this.userData.mainmodule)) {
            this.userData.mainmodule = 6;
            this.uniqueModuleCedChild = true;
          }
          const subModulesEspecialist = this.modules
            .filter((item) => item.parent_id === 11)
            .map((item) => item.id);
          if (subModulesEspecialist.includes(this.userData.mainmodule)) {
            this.userData.mainmodule = 11;
            this.uniqueModuleCedChild = true;
          }

          // }
          if (this.userData.newarr.length > 1) {
            const index = this.userData.newarr.findIndex(
              (element) => element.id_module === 21
            );
            this.deletedItem = this.userData.newarr[index];
            this.wasDeleted = true;
            if (this.userData.unique_module_ced_module === 1) {
              this.userData.newarr.splice(index, 1);
            }
          }
          if (this.userData.newarr.length <= 0) {
            isAnElement = false;
            isMain = true;
          } else if (this.userData.newarr.length === 1) {
            this.userData.mainmodule = this.userData.newarr[0].id_module;
            // if (!this.uniqueModuleCedChild) {
            //   this.userData.mainmodule = 6;
            // }
          } else if (
            !this.userData.newarr.find(
              (item) => item.id_module === this.userData.mainmodule
            )
          ) {
            isMain = true;
            if (this.userData.mainmodule === 21) {
              isMain = false;
            }
          }
        }

        if (this.userData.mainmodule === 21) {
          this.userData.mainmodule = 6;
        }

        if (
          answer &&
          !refForm.isValidEmail &&
          (this.userData.admin === 1 ||
            (this.userData.admin === 2 && this.userData.mainmodule !== null)) &&
          isAnElement &&
          !isMain
        ) {
          const result = await this.showConfirmSwal("Are you sure?");
          if (result.value) {
            if (this.containsChiefCreditExpertsDigital) {
              this.modules.forEach((item) => {
                if (item.parent_id === 21) {
                  this.userData.newarr.push({
                    id_module: item.id,
                    module: item.name,
                    parent_id: item.parent_id,
                    id_role: 17,
                    typesenior: 0,
                  });
                }
              });
              if (!this.containsCreditExpertsRegular) {
                this.userData.newarr.push({
                  id_module: 6, // credit experts regular
                  module: "CREDIT EXPERTS REGULAR",
                  parent_id: null,
                  id_role: 3,
                  typesenior: 0,
                });
                // this.userData.mainmodule=6
              }
            } else if (this.containsAChildOfCreditExpertsDigital) {
              this.userData.newarr.push({
                id_module: 21, // credit experts digital
                module: "CREDIT EXPERTS DIGITAL",
                parent_id: null,
                id_role: 15,
                typesenior: 0,
              });
              if (!this.containsCreditExpertsRegular) {
                this.userData.newarr.push({
                  id_module: 6, // credit experts regular
                  module: "CREDIT EXPERTS REGULAR",
                  parent_id: null,
                  id_role: 3,
                  typesenior: 0,
                });
                this.userData.mainmodule = 6;
              }
            }

            if (this.containsChiefEspecialists) {
              this.modules.forEach((item) => {
                if (item.parent_id === 11) {
                  this.userData.newarr.push({
                    id_module: item.id,
                    module: item.name,
                    parent_id: item.parent_id,
                    id_role: 17,
                    typesenior: 0,
                  });
                }
              });
            } else {
              if (this.especialistSubModules) {
                this.userData.newarr.push({
                  id_module: 11,
                  module: "Specialist",
                  parent_id: null,
                  id_role: 3,
                  typesenior: 0,
                });
              }
            }

            this.userData.newarr.forEach((item) => {
              this.idsUserModule.push(item.id_module);
            });

            this.$set(
              this.userData,
              "idsUserModule",
              this.idsUserModule.join(",")
            );

            this.addPreloader();

            const data = this.$route.params.id
              ? await EditService.userUpdate(refForm.userData)
              : await EditService.userCreate(refForm.userData);
            if (data.status === 200) {
              if (this.$route.params.id) {
                this.showSuccessSwal();
              } else {
                this.showSuccessSwal();
              }
              this.$router.push({ path: `/${this.routeModule}` });
            }
            this.removePreloader();
            if (this.wasDeleted) this.userData.newarr.push(this.deletedItem);
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "You must fill in the necessary information",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        }
      } catch (e) {
        this.showErrorSwal(e);
        this.removePreloader();
        throw e;
      }
    },
  },
};
</script>
